<!--
--------------------------------------------------------------------------------
<copyright file="QuotesFigures.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('report.quotes') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figure') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nAccepted') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_accepted') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nRejected') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_rejected') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nCustomersPerGroup') }}</summary>
      <p class="text">{{ $t('widget_info_text.customers_groups_abc') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_a') }}</span>
          - {{ $t('widget_info_text.customer_group_a_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_b') }}</span>
          - {{ $t('widget_info_text.customer_group_b_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_c') }}</span>
          - {{ $t('widget_info_text.customer_group_c_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('report.business_console.nCustomers') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_customers') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.newCustomers') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_new_customers') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgConversionTime') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_avg_conv_time') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgAmount') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_avg_amount') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.business_console.avgConvertedAmount') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_figures_avg_converted_amount') }}</p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class QuotesFiguresInfo extends Vue {}
</script>
